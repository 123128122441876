// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../webfonts/fa-light-300.eot";
import ___CSS_LOADER_URL_IMPORT_1___ from "../webfonts/fa-light-300.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "../webfonts/fa-light-300.woff";
import ___CSS_LOADER_URL_IMPORT_3___ from "../webfonts/fa-light-300.ttf";
import ___CSS_LOADER_URL_IMPORT_4___ from "../webfonts/fa-light-300.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#fontawesome" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*!\n * Font Awesome Pro 5.14.0 by @fontawesome - https://fontawesome.com\n * License - https://fontawesome.com/license (Commercial License)\n */\n@font-face{font-family:\"Font Awesome 5 Pro\";font-style:normal;font-weight:300;font-display:block;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"svg\")}.fal{font-family:\"Font Awesome 5 Pro\";font-weight:300}", "",{"version":3,"sources":["webpack://src/common/fontawesome-subset/css/light.min.css"],"names":[],"mappings":"AAAA;;;EAGE;AACF,WAAW,gCAAgC,CAAC,iBAAiB,CAAC,eAAe,CAAC,kBAAkB,CAAC,2CAAqC,CAAC,uSAA+R,CAAC,KAAK,gCAAgC,CAAC,eAAe","sourcesContent":["/*!\n * Font Awesome Pro 5.14.0 by @fontawesome - https://fontawesome.com\n * License - https://fontawesome.com/license (Commercial License)\n */\n@font-face{font-family:\"Font Awesome 5 Pro\";font-style:normal;font-weight:300;font-display:block;src:url(../webfonts/fa-light-300.eot);src:url(../webfonts/fa-light-300.eot?#iefix) format(\"embedded-opentype\"),url(../webfonts/fa-light-300.woff2) format(\"woff2\"),url(../webfonts/fa-light-300.woff) format(\"woff\"),url(../webfonts/fa-light-300.ttf) format(\"truetype\"),url(../webfonts/fa-light-300.svg#fontawesome) format(\"svg\")}.fal{font-family:\"Font Awesome 5 Pro\";font-weight:300}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
