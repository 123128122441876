export const translations = [
    {
        "html_id": "heelmaincolor_caption",
        "APP omschrijving": "Hak hoofdkleur:",
        "german": "Absatz Erstfarbe",
        "english": "Main color heel:"
    },
    {
        "html_id": "heelsecondcolor_caption",
        "APP omschrijving": "Hak tweede kleur:",
        "german": "Absatz Zweitfarbe",
        "english": "Second color heel:"
    },
    {
        "html_id": "order_id_one_caption",
        "APP omschrijving": "Order ID (1)",
        "german": "Bestellnummer:",
        "english": "Order nummer"
    },
    {
        "html_id": "model_information_caption",
        "APP omschrijving": "Model informatie:",
        "german": "Modellinformation:",
        "english": "Model Information:"
    },
    {
        "html_id": "modelname_caption",
        "APP omschrijving": "Modelnaam:",
        "german": "Modellname:",
        "english": "Modelname:"
    },
    {
        "html_id": "modelnumber_caption",
        "APP omschrijving": "Modelnummer:",
        "german": "Modellnummer:",
        "english": "Modelnumber:"
    },
    {
        "html_id": "order_title",
        "APP omschrijving": "ORDER FORMULIER",
        "german": "Bestellformular:",
        "english": "Order form:"
    },
    {
        "html_id": "order_details_caption",
        "APP omschrijving": "Order Informatie",
        "german": "Bestellinformationen:",
        "english": "Order information"
    },
    {
        "html_id": "organisation_caption",
        "APP omschrijving": "Organisatie",
        "german": "Firma:",
        "english": "Organisation"
    },
    {
        "html_id": "boarding_caption",
        "APP omschrijving": "Stootband",
        "german": "Verschalung",
        "english": "Boarding"
    },
    {
        "html_id": "toe_thickness_caption",
        "APP omschrijving": "Teendikte:",
        "german": "Spitze Stärke:",
        "english": "thickness toes:"
    },
    {
        "html_id": "construction_details_title",
        "APP omschrijving": "Uitvoering Onderwerk",
        "german": "Bodenspezifikationen",
        "english": "Execution bottoming"
    },
    {
        "html_id": "upper_details_caption",
        "APP omschrijving": "Uitvoering Schacht",
        "german": "Schaftspezifikationen",
        "english": "Execution upper"
    },
    {
        "html_id": "amount_class",
        "APP omschrijving": "Aantal",
        "german": "Menge",
        "english": "number (like in number of eyelets)"
    },
    {
        "html_id": "height_class",
        "APP omschrijving": "Hoogte",
        "german": "Höhe",
        "english": "height"
    },
    {
        "html_id": "left_class",
        "APP omschrijving": "Links",
        "german": "Links",
        "english": "Left"
    },
    {
        "html_id": "heellining_caption",
        "APP omschrijving": "Achtervoering",
        "german": "Quartierfutter:",
        "english": "heelpart lining"
    },
    {
        "html_id": "additional_upper_particularities_class",
        "APP omschrijving": "Anders",
        "german": "alternative:",
        "english": "alternitive"
    },
    {
        "html_id": "heelheight_caption",
        "APP omschrijving": "Hakhoogte:",
        "german": "Absatzsprengung",
        "english": "Heel height"
    },
    {
        "html_id": "",
        "APP omschrijving": "Hielpolster:",
        "german": "Abschlusspolster:",
        "english": "Heelpolster"
    },
    {
        "html_id": "construction_caption",
        "APP omschrijving": "Opbouw",
        "german": "Aufbau",
        "english": "from material"
    },
    {
        "html_id": "remarks_caption",
        "APP omschrijving": "Opmerkingen",
        "german": "Bemerkungen",
        "english": "Remarks"
    },
    {
        "html_id": "order_id_two_caption",
        "APP omschrijving": "Order ID (2)",
        "german": "Leistennummer:",
        "english": "Lastnumber"
    },
    {
        "html_id": "welt_caption",
        "APP omschrijving": "Rand",
        "german": "Rahmen",
        "english": "welt"
    },
    {
        "html_id": "lining_caption",
        "APP omschrijving": "Voorvoering",
        "german": "Blattfutter",
        "english": "Vamp lining"
    },
    {
        "html_id": "",
        "APP omschrijving": "2 -fase",
        "german": "2 Phasen Rolle",
        "english": "2-phase roller"
    },
    {
        "html_id": "",
        "APP omschrijving": "Aangeleverd/ bijgeleverd",
        "german": "Mitgeliefert",
        "english": "delivered"
    },
    {
        "html_id": "toplift_caption",
        "APP omschrijving": "Achterlap",
        "german": "Absatzfleck",
        "english": "top lift  (heel cap)"
    },
    {
        "html_id": "rolling_caption",
        "APP omschrijving": "Afwikkeling",
        "german": "Abrolltechnik",
        "english": "roller/ addition in bottoming"
    },
    {
        "html_id": "rolling_app_caption",
        "APP omschrijving": "Afwikkeling",
        "german": "Abrolltechnik",
        "english": "roller"
    },
    {
        "html_id": "rolling_type_caption",
        "APP omschrijving": "Afwikkeling type",
        "german": "Rollen-Typ",
        "english": "roller type"
    },
    {
        "html_id": "according_model_class",
        "APP omschrijving": "Als model",
        "german": "wie Modell",
        "english": "according model"
    },
    {
        "html_id": "",
        "APP omschrijving": "Als zoolmateriaal",
        "german": "gleiche wie Außensohle",
        "english": "matching outsole"
    },
    {
        "html_id": "antislip_caption",
        "APP omschrijving": "Antislip",
        "german": "Futterhose",
        "english": "Anti-slip heel"
    },
    {
        "html_id": "",
        "APP omschrijving": "Bal",
        "german": "Ballen",
        "english": "sole bone"
    },
    {
        "html_id": "ball_thickness_caption",
        "APP omschrijving": "Baldikte:",
        "german": "Ballen Stärke:",
        "english": "sole bone thickness:"
    },
    {
        "html_id": "leg_girth_title",
        "APP omschrijving": "Beenmaten",
        "german": "Beinmaβe",
        "english": "legmeasurements"
    },
    {
        "html_id": "",
        "APP omschrijving": "Beige",
        "german": "Beige",
        "english": "Beige"
    },
    {
        "html_id": "",
        "APP omschrijving": "Bijpassend hoofdkleur leer",
        "german": "passend zum Oberleder",
        "english": "Equal to maincolor leather"
    },
    {
        "html_id": "insole_caption",
        "APP omschrijving": "Binnenzool",
        "german": "Brandsohle",
        "english": "Insole"
    },
    {
        "html_id": "blue_class",
        "APP omschrijving": "blauw",
        "german": "Blau",
        "english": "blue"
    },
    {
        "html_id": "",
        "APP omschrijving": "blokhak",
        "german": "Blockabsatz",
        "english": "wooden heel no.1"
    },
    {
        "html_id": "",
        "APP omschrijving": "BOA sluiting",
        "german": "Drehverschluss",
        "english": "BOA lock system"
    },
    {
        "html_id": "heelpolster_caption",
        "APP omschrijving": "Boordpolster",
        "german": "Abschlusspolster",
        "english": "heel collar polster"
    },
    {
        "html_id": "",
        "APP omschrijving": "bordeaux",
        "german": "bordeaux",
        "english": "Bordeaux"
    },
    {
        "html_id": "brown_class",
        "APP omschrijving": "Bruin",
        "german": "braun",
        "english": "brown"
    },
    {
        "html_id": "",
        "APP omschrijving": "Carbon",
        "german": "Carbon Sohlenversteifung",
        "english": "carbon solestiffening"
    },
    {
        "html_id": "",
        "APP omschrijving": "Carbon dubbel",
        "german": "doppelte Carbon Sohlenversteifung",
        "english": "carbon double solestiffening"
    },
    {
        "html_id": "category_class",
        "APP omschrijving": "Categorie",
        "german": "Kategorie",
        "english": "material"
    },
    {
        "html_id": "contrefort_class",
        "APP omschrijving": "Contrefort",
        "german": "Hinterkappe",
        "english": "counter stiffening"
    },
    {
        "html_id": "thickness_class",
        "APP omschrijving": "Dikte",
        "german": "Dicke",
        "english": "Thickness"
    },
    {
        "html_id": "stitching_strength_class",
        "APP omschrijving": "Dikte (dikte bij stiksel)",
        "german": "Nahtstärke",
        "english": "yarn"
    },
    {
        "html_id": "",
        "APP omschrijving": "Doorgenaaid op loopzool",
        "german": "Durchgenäht auf Laufsohle",
        "english": "stitched through outsole"
    },
    {
        "html_id": "",
        "APP omschrijving": "Doorgenaaid op rand",
        "german": "Durchgenäht auf Laufsohle",
        "english": "stitched through insole"
    },
    {
        "html_id": "",
        "APP omschrijving": "Easyrollers",
        "german": "Easyrollers",
        "english": "easyrollers"
    },
    {
        "html_id": "",
        "APP omschrijving": "Enkel: polstering 2",
        "german": "Knöchelpolster Medial",
        "english": "ankle med. Ø 100 mm"
    },
    {
        "html_id": "",
        "APP omschrijving": "Enkel: polstering 3",
        "german": "Knöchelpolster Lateral",
        "english": "ankle lat. Ø 100 mm"
    },
    {
        "html_id": "",
        "APP omschrijving": "Enkel: polstering 4",
        "german": "Knöchelpolster Lateral und Medial",
        "english": "ankle med. + lat. Ø 100 mm"
    },
    {
        "html_id": "",
        "APP omschrijving": "Enkelomgeving, polstering 1",
        "german": "Knöchelpolster Eckig",
        "english": "ankle area"
    },
    {
        "html_id": "",
        "APP omschrijving": "EVA",
        "german": "EVA",
        "english": "EVA"
    },
    {
        "html_id": "",
        "APP omschrijving": "EVA/ rubber",
        "german": "EVA/Gummi",
        "english": "EVA/ rubber combination"
    },
    {
        "html_id": "",
        "APP omschrijving": "Flexibel",
        "german": "Flexibel",
        "english": "flexible"
    },
    {
        "html_id": "",
        "APP omschrijving": "Gaatjes",
        "german": "Versteckte Ösen",
        "english": "holes"
    },
    {
        "html_id": "",
        "APP omschrijving": "Gelijmd",
        "german": "Geklebt",
        "english": "Glued"
    },
    {
        "html_id": "",
        "APP omschrijving": "Generale polstering",
        "german": "Quartier Polster",
        "english": "complete"
    },
    {
        "html_id": "",
        "APP omschrijving": "Gespriemen",
        "german": "Gürtelschnalle",
        "english": "buckle"
    },
    {
        "html_id": "",
        "APP omschrijving": "Grijs",
        "german": "Grau",
        "english": "grey"
    },
    {
        "html_id": "",
        "APP omschrijving": "groen",
        "german": "Grün",
        "english": "green"
    },
    {
        "html_id": "heel_specifics_caption",
        "APP omschrijving": "Hak specificaties",
        "german": "Absatzspezifikationen",
        "english": "Heel specifications"
    },
    {
        "html_id": "heeltype_caption",
        "APP omschrijving": "Hak type",
        "german": "Absatz-Typ",
        "english": "Heel type"
    },
    {
        "html_id": "heelrolling_caption",
        "APP omschrijving": "Hakafronding",
        "german": "Absatz-Rolle",
        "english": "roller on heel"
    },
    {
        "html_id": "heelbuffer_caption",
        "APP omschrijving": "Hakbuffering",
        "german": "Pufferabsatz",
        "english": "shock absorber heel"
    },
    {
        "html_id": "",
        "APP omschrijving": "Haken",
        "german": "Haken",
        "english": "hooks"
    },
    {
        "html_id": "heel_expansion_caption",
        "APP omschrijving": "Hakschoring",
        "german": "Absatzverbreiterung",
        "english": "heelform to"
    },
    {
        "html_id": "",
        "APP omschrijving": "Herenhak",
        "german": "Herren Absatzfleck",
        "english": "Men's toplift"
    },
    {
        "html_id": "",
        "APP omschrijving": "Hiel",
        "german": "Ferse",
        "english": "heel"
    },
    {
        "html_id": "heel_thickness_caption",
        "APP omschrijving": "Hieldikte:",
        "german": "Ferse Stärke:",
        "english": "Thickness heel:"
    },
    {
        "html_id": "heelreinforcement_caption",
        "APP omschrijving": "Hielomsluiting",
        "german": "Hinterkappen",
        "english": "reinforcement"
    },
    {
        "html_id": "",
        "APP omschrijving": "Honing",
        "german": "Honig",
        "english": "honey"
    },
    {
        "html_id": "mainstitching_caption",
        "APP omschrijving": "Hoofdstiksel",
        "german": "Montagenähte",
        "english": "main stitches"
    },
    {
        "html_id": "vamppoint_caption",
        "APP omschrijving": "Inschotpunt",
        "german": "Derbypunkt",
        "english": "vamp point (IP)"
    },
    {
        "html_id": "color_class",
        "APP omschrijving": "Kleur",
        "german": "Farbe",
        "english": "colour"
    },
    {
        "html_id": "",
        "APP omschrijving": "Klittenband",
        "german": "Klettverschluss",
        "english": "velcro med. (tongue)"
    },
    {
        "html_id": "",
        "APP omschrijving": "Klittenband met passant",
        "german": "Klettverschluss mit Umlenkrolle",
        "english": "velcro with roller"
    },
    {
        "html_id": "",
        "APP omschrijving": "Klittenband zonder passant",
        "german": "Klettverschluss ohne Umlenkrolle",
        "english": "velcro without roller"
    },
    {
        "html_id": "",
        "APP omschrijving": "kunststof",
        "german": "Lederfaser",
        "english": "synthetic"
    },
    {
        "html_id": "lateral_class",
        "APP omschrijving": "Lateraal",
        "german": "Lateral",
        "english": "lat."
    },
    {
        "html_id": "",
        "APP omschrijving": "Leder",
        "german": "Leder",
        "english": "leather"
    },
    {
        "html_id": "",
        "APP omschrijving": "lila",
        "german": "Lila",
        "english": "lilac"
    },
    {
        "html_id": "outsole_caption",
        "APP omschrijving": "Loopzool",
        "german": "Laufsohle",
        "english": "Outsole"
    },
    {
        "html_id": "construction_execution_caption",
        "APP omschrijving": "Maakwijze",
        "german": "Machart",
        "english": "make way info"
    },
    {
        "html_id": "material_class",
        "APP omschrijving": "Materiaal",
        "german": "Material",
        "english": "material"
    },
    {
        "html_id": "medial_class",
        "APP omschrijving": "Mediaal",
        "german": "Medial",
        "english": "med."
    },
    {
        "html_id": "",
        "APP omschrijving": "Mediaal & Lateraal",
        "german": "Medial und Lateral",
        "english": "med. + lat."
    },
    {
        "html_id": "",
        "APP omschrijving": "Meindl polstering",
        "german": "Meindl Polster",
        "english": "Meindl (8 mm from counter stiffing up)"
    },
    {
        "html_id": "",
        "APP omschrijving": "Militairhaken",
        "german": "Militär-Haken",
        "english": "Militairy hook"
    },
    {
        "html_id": "",
        "APP omschrijving": "Naar achteren",
        "german": "Nach hinten stellen",
        "english": "place to the back"
    },
    {
        "html_id": "",
        "APP omschrijving": "Naar voren",
        "german": "Nach vorne stellen",
        "english": "place to the front"
    },
    {
        "html_id": "",
        "APP omschrijving": "naturel",
        "german": "Naturel",
        "english": "natural"
    },
    {
        "html_id": "nose_material_caption",
        "APP omschrijving": "Neusmateriaal",
        "german": "Vorderkappen",
        "english": "toe cap"
    },
    {
        "html_id": "",
        "APP omschrijving": "Niet relevant",
        "german": "n.a.",
        "english": "n.a."
    },
    {
        "html_id": "",
        "APP omschrijving": "Normaal",
        "german": "Normale abrollung",
        "english": "normal roller"
    },
    {
        "html_id": "",
        "APP omschrijving": "Nylon",
        "german": "Nylon",
        "english": "nylon"
    },
    {
        "html_id": "",
        "APP omschrijving": "Olie-zuurbestendig",
        "german": "Öl- und Sauerbeständig",
        "english": "oil-acid resistant"
    },
    {
        "html_id": "bottoming_caption",
        "APP omschrijving": "Onderwerk",
        "german": "BODENBAU",
        "english": "BOTTOMING"
    },
    {
        "html_id": "quarterspace_caption",
        "APP omschrijving": "Openstand",
        "german": "Abstand der Schnürung",
        "english": "quarter opening"
    },
    {
        "html_id": "",
        "APP omschrijving": "oranje",
        "german": "Orange",
        "english": "orange"
    },
    {
        "html_id": "upperleather_title",
        "APP omschrijving": "Overleder",
        "german": "Oberleder",
        "english": "leather"
    },
    {
        "html_id": "upperleather_1_caption",
        "APP omschrijving": "Overleder 1:",
        "german": "Oberleder 1",
        "english": "leather 1:"
    },
    {
        "html_id": "upperleather_2_caption",
        "APP omschrijving": "Overleder 2:",
        "german": "Oberleder 2",
        "english": "leather 2:"
    },
    {
        "html_id": "upperleather_3_caption",
        "APP omschrijving": "Overleder 3:",
        "german": "Oberleder 3",
        "english": "leather 3:"
    },
    {
        "html_id": "upperleather_4_caption",
        "APP omschrijving": "Overleder 4:",
        "german": "Oberleder 4",
        "english": "leather 4:"
    },
    {
        "html_id": "polster_title",
        "APP omschrijving": "Polster",
        "german": "Polster",
        "english": "polster/ foam"
    },
    {
        "html_id": "",
        "APP omschrijving": "Polyfasisch",
        "german": "Mehrphasige Rolle",
        "english": "polyphasic roller"
    },
    {
        "html_id": "",
        "APP omschrijving": "polypropyleen",
        "german": "Polypropyleen",
        "english": "polyprop"
    },
    {
        "html_id": "right_class",
        "APP omschrijving": "Rechts",
        "german": "Rechts",
        "english": "right"
    },
    {
        "html_id": "",
        "APP omschrijving": "Rhenoflex",
        "german": "Rhenoflex Sohlenversteifung",
        "english": "Rhenoflex solestiffening"
    },
    {
        "html_id": "",
        "APP omschrijving": "Rhenoflex",
        "german": "Rhenoflex",
        "english": "Rhenoflex"
    },
    {
        "html_id": "",
        "APP omschrijving": "Ringen",
        "german": "Ösen",
        "english": "eyelets"
    },
    {
        "html_id": "",
        "APP omschrijving": "Rits",
        "german": "Reiβverschluss",
        "english": "zipper"
    },
    {
        "html_id": "",
        "APP omschrijving": "rood",
        "german": "Rot",
        "english": "red"
    },
    {
        "html_id": "",
        "APP omschrijving": "Rubber",
        "german": "Gummi",
        "english": "Rubber"
    },
    {
        "html_id": "fillsole_caption",
        "APP omschrijving": "Ruimtezool/vulzool",
        "german": "Extra Korksohle",
        "english": "extra layer"
    },
    {
        "html_id": "upper_height_caption",
        "APP omschrijving": "Schachthoogte (incl. supplement)",
        "german": "Schafthöhe (inkl. Bettung)",
        "english": "Upper height"
    },
    {
        "html_id": "polster_upper_caption",
        "APP omschrijving": "Schachtpolster",
        "german": "Quartierpolster",
        "english": "polster upper"
    },
    {
        "html_id": "decostitching_caption",
        "APP omschrijving": "Sierstiksel",
        "german": "Ziernähte",
        "english": "decoration stitching"
    },
    {
        "html_id": "",
        "APP omschrijving": "Ski-haken",
        "german": "Ski Haken",
        "english": "ski hooks  (ring)"
    },
    {
        "html_id": "",
        "APP omschrijving": "sleehak",
        "german": "keilabsatz",
        "english": "wedged heel no:2"
    },
    {
        "html_id": "",
        "APP omschrijving": "Sleehak alléén zijkanten hol",
        "german": "keilabsatz mit Steg",
        "english": "wedged heel no:4"
    },
    {
        "html_id": "",
        "APP omschrijving": "sleehak hol",
        "german": "keilabsatz angeschweift",
        "english": "wedged heel no:3"
    },
    {
        "html_id": "closing_one_caption",
        "APP omschrijving": "Sluiting 1",
        "german": "Verschluss 1",
        "english": "fastening 1"
    },
    {
        "html_id": "closing_two_caption",
        "APP omschrijving": "Sluiting 2",
        "german": "Verschluss 2",
        "english": "fastening 2"
    },
    {
        "html_id": "closing_three_caption",
        "APP omschrijving": "Sluiting 2",
        "german": "Verschluss 3",
        "english": "fastening 3"
    },
    {
        "html_id": "",
        "APP omschrijving": "staal",
        "german": "Stahl",
        "english": "steel"
    },
    {
        "html_id": "",
        "APP omschrijving": "Standaard",
        "german": "Standard",
        "english": "standard"
    },
    {
        "html_id": "",
        "APP omschrijving": "Taupe",
        "german": "Taupe",
        "english": "Taupe"
    },
    {
        "html_id": "toes_caption",
        "APP omschrijving": "Tenen",
        "german": "Zehen",
        "english": "toes"
    },
    {
        "html_id": "",
        "APP omschrijving": "Tong",
        "german": "Lasche",
        "english": "tongue"
    },
    {
        "html_id": "tonguefixation_caption",
        "APP omschrijving": "Tong bevestiging",
        "german": "Lasche fixieren",
        "english": "fixation tongue"
    },
    {
        "html_id": "tonguepolster_caption",
        "APP omschrijving": "Tong polster",
        "german": "Laschenpolster",
        "english": "polster tongue"
    },
    {
        "html_id": "",
        "APP omschrijving": "turqouise",
        "german": "Turkis",
        "english": "turqouise"
    },
    {
        "html_id": "tvo_class",
        "APP omschrijving": "TVO",
        "german": "Hohe Hinterkappe",
        "english": "TVO"
    },
    {
        "html_id": "",
        "APP omschrijving": "TVO hoogte",
        "german": "Höhe der Hinterkappe",
        "english": "height of the stiffening"
    },
    {
        "html_id": "",
        "APP omschrijving": "TVO uitvoering",
        "german": "Art der Hinterkappe",
        "english": "TVO no:"
    },
    {
        "html_id": "type_class",
        "APP omschrijving": "Type",
        "german": "Typ",
        "english": "type"
    },
    {
        "html_id": "execution_class",
        "APP omschrijving": "Uitvoering",
        "german": "Variante",
        "english": "Execution"
    },
    {
        "html_id": "",
        "APP omschrijving": "Uitvoering (bij rand)",
        "german": "Material",
        "english": "material"
    },
    {
        "html_id": "",
        "APP omschrijving": "Uitvoering (bij stootband)",
        "german": "Typ",
        "english": "type"
    },
    {
        "html_id": "",
        "APP omschrijving": "Vaststikken",
        "german": "Laschenschnitt",
        "english": "tongue incision"
    },
    {
        "html_id": "",
        "APP omschrijving": "Vertraagd",
        "german": "Langsame",
        "english": "slow roller"
    },
    {
        "html_id": "",
        "APP omschrijving": "Vervroegd",
        "german": "Schnelle",
        "english": "fast roller"
    },
    {
        "html_id": "",
        "APP omschrijving": "Veters",
        "german": "Schnürung",
        "english": "fastening by laces"
    },
    {
        "html_id": "lining_title",
        "APP omschrijving": "Voering",
        "german": "Futter",
        "english": "lining"
    },
    {
        "html_id": "",
        "APP omschrijving": "Vorm",
        "german": "Formsohlen",
        "english": "pre-fab outsole"
    },
    {
        "html_id": "",
        "APP omschrijving": "wit",
        "german": "Weiβ",
        "english": "White"
    },
    {
        "html_id": "",
        "APP omschrijving": "Zacht (ercoflex)",
        "german": "Erkolex (weich)",
        "english": "Ercoflex"
    },
    {
        "html_id": "",
        "APP omschrijving": "Zand",
        "german": "sand",
        "english": "Sand (like in color)"
    },
    {
        "html_id": "solestiffner_caption",
        "APP omschrijving": "Zoolverstijving",
        "german": "Sohlenversteifung",
        "english": "solestiffening"
    },
    {
        "html_id": "black_class",
        "APP omschrijving": "Zwart",
        "german": "schwarz",
        "english": "black"
    },
    {
        "html_id": "small_hooks_class",
        "APP omschrijving": "Kleine haken",
        "german": "Kleine Haken",
        "english": "small hooks"
    },
    {
        "html_id": "small_eyelets_class",
        "APP omschrijving": "Kleine ringen",
        "german": "Kleine Ösen",
        "english": "small eyelets"
    },
    {
        "html_id": "large_hooks_class",
        "APP omschrijving": "Grote haken",
        "german": "Großen Haken",
        "english": "big hooks"
    },
    {
        "html_id": "large_eyelets_class",
        "APP omschrijving": "Grote ringen",
        "german": "Großen Ösen",
        "english": "big eyelets"
    },
    {
        "html_id": "color_1_class",
        "APP omschrijving": "Donker gebrand",
        "german": "Dunkel",
        "english": "Gun metal"
    },
    {
        "html_id": "color_2_class",
        "APP omschrijving": "Licht gebrand",
        "german": "Anthrazit",
        "english": "bronze"
    },
    {
        "html_id": "color_3_class",
        "APP omschrijving": "Nikkel",
        "german": "Bronze",
        "english": "Silver"
    },
    {
        "html_id": "color_4_class",
        "APP omschrijving": "",
        "german": "Silber",
        "english": "Gold"
    },
    {
        "html_id": "extra_length_class",
        "APP omschrijving": "",
        "german": "Klettverschluss extra Länge",
        "english": "extra lenght+"
    },
    {
        "html_id": "",
        "APP omschrijving": "",
        "german": "",
        "english": ""
    },
    {
        "html_id": "midsole_caption",
        "APP omschrijving": "Tussenzool",
        "german": "Zwischensohle",
        "english": "Midsole"
    },
    {
        "html_id": "accountCaption",
        "APP omschrijving": "",
        "german": "Konto",
        "english": "Account"
    },
    {
        "html_id": "signIn_class",
        "APP omschrijving": "",
        "german": "Einloggen",
        "english": "Sign in"
    },
    {
        "html_id": "signOut",
        "APP omschrijving": "",
        "german": "Ausloggen",
        "english": "Sign out"
    },
    {
        "html_id": "signUp",
        "APP omschrijving": "",
        "german": "",
        "english": "Sign up"
    },
    {
        "html_id": "resetPassword",
        "APP omschrijving": "",
        "german": "Passwort vergessen",
        "english": "Forgot password"
    },
    {
        "html_id": "orderMenuCaption",
        "APP omschrijving": "",
        "german": "Meine Bestellungen",
        "english": "Order menu"
    },
    {
        "html_id": "openOrdersCaption",
        "APP omschrijving": "",
        "german": "Auftragssynchronisation",
        "english": "Synchronize orders"
    },
    {
        "html_id": "orderHistoryCaption",
        "APP omschrijving": "",
        "german": "Bestellverlauf",
        "english": "Order history"
    },
    {
        "html_id": "customOrderCaption",
        "APP omschrijving": "",
        "german": "Custom Order",
        "english": "Custom order"
    },
    {
        "html_id": "downloadImagesCaption",
        "APP omschrijving": "",
        "german": "",
        "english": ""
    },
    {
        "html_id": "settingsMenuCaption",
        "APP omschrijving": "",
        "german": "",
        "english": ""
    },
    {
        "html_id": "languageSettingsCaption",
        "APP omschrijving": "",
        "german": "Sprache ändern",
        "english": "Change language"
    },
    {
        "html_id": "orderButtonCaption",
        "APP omschrijving": "",
        "german": "bestellen!",
        "english": "Order!"
    },
    {
        "html_id": "upperSectionCaption",
        "APP omschrijving": "",
        "german": "SCHAFT",
        "english": "UPPER"
    },
    {
        "html_id": "vamppoint_caption_app",
        "APP omschrijving": "Inschotpunt",
        "german": "Derbypunkt",
        "english": "vamp point"
    },
    {
        "html_id": "low_shoe_class",
        "APP omschrijving": "",
        "german": "Halbschuh",
        "english": "Low shoe"
    },
    {
        "html_id": "amount_app_class",
        "APP omschrijving": "Aantal",
        "german": "Menge",
        "english": "number"
    },
    {
        "html_id": "welt_number_caption",
        "APP omschrijving": "Rand",
        "german": "nummer",
        "english": "number"
    },
    {
        "html_id": "heelcolor_caption",
        "APP omschrijving": "Hak hoofdkleur:",
        "german": "Absatzfarbe",
        "english": "Heelcolor"
    },
    {
        "html_id": "yes_class",
        "APP omschrijving": "",
        "german": "Ja",
        "english": "Yes"
    },
    {
        "html_id": "no_class",
        "APP omschrijving": "",
        "german": "Nein",
        "english": "No"
    },
    {
        "html_id": "heel_thickness_class",
        "APP omschrijving": "Hieldikte:",
        "german": "Ferse Stärke",
        "english": "Thickness heel"
    },
    {
        "html_id": "ball_thickness_class",
        "APP omschrijving": "Baldikte:",
        "german": "Ballen Stärke",
        "english": "sole bone thickness"
    },
    {
        "html_id": "toe_thickness_class",
        "APP omschrijving": "Teendikte:",
        "german": "Spitze Stärke",
        "english": "thickness toes"
    },
    {
        "html_id": "rolling_type_class",
        "APP omschrijving": "Afwikkeling type",
        "german": "Typ Rolle",
        "english": "roller type"
    },
    {
        "html_id": "order_id_caption",
        "APP omschrijving": "",
        "german": "Patientenidentifikation",
        "english": "Client identification"
    },
    {
        "html_id": "female_caption",
        "APP omschrijving": "",
        "german": "Frau",
        "english": "Female"
    },
    {
        "html_id": "male_caption",
        "APP omschrijving": "",
        "german": "Herr",
        "english": "Male"
    },
    {
        "html_id": "not_signed_in_caption",
        "APP omschrijving": "",
        "german": "Nicht eingeloggt!",
        "english": "Not logged in!"
    },
    {
        "html_id": "signout_intent",
        "APP omschrijving": "",
        "german": "Möchten Sie sich abmelden?",
        "english": "Are you sure you want to signout?"
    },
    {
        "html_id": "passwords_not_match",
        "APP omschrijving": "",
        "german": "Passwörter stimmen nicht überein!",
        "english": "Passwords don't match"
    },
    {
        "html_id": "signedin_confirm",
        "APP omschrijving": "",
        "german": "Du bist eingeloggt!",
        "english": "You are signed in!"
    },
    {
        "html_id": "password_changed_confirmation",
        "APP omschrijving": "",
        "german": "Ihr Passwort wurde erfolgreich geändert und Sie sind angemeldet.",
        "english": ""
    },
    {
        "html_id": "confirm_email",
        "APP omschrijving": "",
        "german": "E-Mail-Adresse bestätigen",
        "english": "Confirm emailaddress"
    },
    {
        "html_id": "password_changed_confirmation_before_signin",
        "APP omschrijving": "",
        "german": "Das Passwort wurde erfolgreich geändert. Sie können sich jetzt mit Ihrem neuen Passwort anmelden.",
        "english": "The password has been changed successfully, you can now log in with your new password."
    },
    {
        "html_id": "SignInHeaderMessage",
        "APP omschrijving": "",
        "german": "Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um sich anzumelden. Sie können sich nur mit einem von Schein bereitgestellten Benutzerkonto anmelden.",
        "english": "Enter your email address and password to log in."
    },
    {
        "html_id": "email_class",
        "APP omschrijving": "",
        "german": "E-Mail-Adresse",
        "english": "E-Mail-Address"
    },
    {
        "html_id": "password_class",
        "APP omschrijving": "",
        "german": "Passwort",
        "english": "Password"
    },
    {
        "html_id": "forgot_password_caption",
        "APP omschrijving": "",
        "german": "Passwort vergessen",
        "english": "Forgot password"
    },
    {
        "html_id": "confirmCodePasswordMessage",
        "APP omschrijving": "Voer hier de (6 cijferige) op je e-mailadres ontvangen bevestigingscode in. (controleer eventueel ook de spambox)",
        "german": "Geben Sie hier den (6-stelligen) Bestätigungscode ein, den Sie an Ihrer E-Mail-Adresse erhalten haben. (Aktivieren Sie ggf. das Spam-Kontrollkästchen.)",
        "english": "We will send a code to the email address you entered. Use this code to then set a new password on the next screen."
    },
    {
        "html_id": "sent_class",
        "APP omschrijving": "",
        "german": "Absenden",
        "english": "Sent"
    },
    {
        "html_id": "take_picture",
        "APP omschrijving": "",
        "german": "Ein Bild Machen",
        "english": "Take picture"
    },
    {
        "html_id": "pick_image",
        "APP omschrijving": "",
        "german": "Bild Auswählen",
        "english": "Pick Image"
    },
    {
        "html_id": "login_class",
        "APP omschrijving": "",
        "german": "Anmeldung",
        "english": "Sign in"
    },
    {
        "html_id": "forgot_password_header_class",
        "APP omschrijving": "",
        "german": "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
        "english": "Enter your email address to reset your password"
    },
    {
        "html_id": "forgotPasswordHeaderMessage",
        "APP omschrijving": "",
        "german": "",
        "english": ""
    },
    {
        "html_id": "order_check_header",
        "APP omschrijving": "",
        "german": "Das Formular ist unvollständig!",
        "english": "The form is not complete yet!"
    },
    {
        "html_id": "order_check_message",
        "APP omschrijving": "",
        "german": "<p style='display: inline' d='quantity'></p> Artikel wurden noch nicht vollständig eingegeben. Verwenden Sie die folgenden Links, um zu den relevanten Elementen zu blättern.",
        "english": "<p style='display: inline' id='quantity'></p> Items have not been entered completely yet. Use the links below to scroll to the relevant items."
    },
    {
        "html_id": "order_check_button",
        "APP omschrijving": "",
        "german": "BESTELLUNG ABSCHICKEN",
        "english": "CHECK ORDER"
    },
    {
        "html_id": "order_check_header_valid",
        "APP omschrijving": "",
        "german": "Das Formular ist vollständig!",
        "english": "The order is complete!"
    },
    {
        "html_id": "order_check_message_valid",
        "APP omschrijving": "",
        "german": "BestelluDrücken Sie die Taste unten, um die Bestellung zu senden. Wenn Sie derzeit keine Internetverbindung haben, wird die Bestellung gespeichert und später versendet.",
        "english": "Press the button below to submit the order. If you don't currently have an internet connection, the order will be saved and shipped later."
    },
    {
        "html_id": "cancel_orderpage",
        "APP omschrijving": "",
        "german": "Möchten Sie die Bestellung wirklich verlassen? Die Eingegebenen Daten werden nicht gespeichert.",
        "english": "Are you sure you want to leave the order page? Any entered data will not be saved."
    }
]